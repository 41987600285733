import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'UXBugOcLFyG9IcCskzk0b0wVmiZgQKqd',
    nodes: [
      {
        host: 'oai1tvjp3w7lx2eqp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  additionalSearchParameters: {
    queryBy: 'name',
    numTypos: 0,
    sortBy: '',
    perPage: 12,
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: 'hiesearches',
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
    placeholder: 'Name of Practice or Physician',
    searchAsYouType: true,
    showReset: true,
    showSubmit: true,
    cssClasses: {
      input: 'pluto-search-input',
    },
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item: `
          <div class="hit-item">
            <div class="hit-name">
              {{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}
            </div>
            <div class="hit-description">
              {{#helpers.highlight}}{ "attribute": "city" }{{/helpers.highlight}},  
              {{#helpers.highlight}}{ "attribute": "state" }{{/helpers.highlight}}
            </div>
          </div>
        `,
    },
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
    scrollTo: false,
  }),
]);

search.start();

// let searchButton = document.getElementsByClassName("ais-SearchBox-submit")[0]
// searchButton.classList.add("btn", "btn-primary", "col-3");

// let searchForm = document.getElementsByClassName("ais-SearchBox-form")[0]
// searchForm.classList.add("row");
